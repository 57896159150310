<template>
  <div>
    <div class="save_title title_top" style="position:fixed;">
        <img class="top_back padding-sm" :src="'img/backw.png'" alt="" @click="goBack">
    </div>
    <div style="font-size:0;">
      <div>
        <vue-canvas-poster :widthPixels="0" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster>
        <img :src="img">
      </div>
    </div>
    <input id="copy-input" type="text" ref="code" style="z-index:-99;position:absolute; left:-1000px;" :value="saveData.link">
    <!-- 底部按钮 -->
    <div class="save_btns">
      
      <div class="display_flex align-items_center">
        <div class="save_item" @click="saveImg">
          <img src="img/saveBtn1.png" alt="">
          <p class="margin-top-xs">保存图片</p>
        </div>
        <button class="btn tag-read save_item margin-left" :data-clipboard-text="saveData.link" @click="copy">
          <img src="img/saveBtn2.png" alt="">
          <p class="margin-top-xs">复制链接</p>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import Clipboard from "clipboard";
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import {getQRcode} from "@/api/api";
export default {
  name: 'save',
  data() {
    return {
      img:'',
      saveData:{},
    };
  },
   computed: {
     painting() {
       return {
        width:`${document.documentElement.clientWidth}px`,
        height:`${document.documentElement.clientHeight}px`,
        background: require('../../../public/img/saveBg.png'),
        views: [
          {
            type: 'image',
            url: `${this.saveData.qr_code}?t=${new Date()}`,
            css: {
              top: `${document.documentElement.clientHeight/1.6-25}px`,
              left: `${document.documentElement.clientWidth/2-88}px`,
              borderRadius: '0',
              width: '176px',
              height: '176px'
            }
          },
          {
            type: 'image',
            url: require('../../../public/img/code_bg.png'),
            css: {
              top: `${document.documentElement.clientHeight/1.6+170}px`,
              left: `${document.documentElement.clientWidth/2-145}px`,
              borderRadius: '0',
              width: '290px',
              height: '48px'
            }
          },
          {
            type: "text",
            text: `邀请码  ${this.saveData.introduce_code}`,
            css: {
              top: `${document.documentElement.clientHeight/1.6+180}px`,
              left: `${document.documentElement.clientWidth/2-145}px`,
              width: "290px",
              textAlign: "center",
              fontSize: "22px",
              fontFamily:'Arial-Black, Arial',
              color:"rgba(255, 255, 255, 1)",
            },
          },
          {
            type: "text",
            text: "温馨提示：若未获取邀请码，请手动输入，绑定邀请码能领取价值388金币的礼包哦",
            css: {
              top: `${document.documentElement.clientHeight/1.6+230}px`,
              left: `${document.documentElement.clientWidth/2-145}px`,
              width: "290px",
              textAlign: "center",
              fontSize: "12px",
              lineHeight:"20px",
              color:"#FFFFFF",
            },
          },
        ],
      }
     }
   },
  mounted () {
    this.fetchData()
  },
  methods: {
    saveImg() {
        var img = this.img
        let str = {
          url:img
        }
        let param = JSON.stringify(str)
        let _this = this
        this.$bridge.callhandler('saveUrl', param, (data) => {
          _this.$toast.success('保存成功')
        })
    },
    fetchData() {
      getQRcode().then(response => {
        this.saveData = response.data
      },
      error => {
        this.$toast.fail(error)
      }).catch((error) => {
        this.$toast.fail(error)
      })
    },
    copy() {
          var clipboard = new Clipboard('.tag-read')
          clipboard.on('success', e => {
              this.$toast('已复制到粘贴板')
              // 释放内存
              clipboard.destroy()
          })
          clipboard.on('error', e => {
              // 不支持复制
              Toast('该浏览器不支持自动复制,请手动输入')
             // 释放内存
             clipboard.destroy()
          })
       },
    goBack(){
      this.$router.go(-1)
    },
    success(src) {
      this.img=src
      console.log(src)
    },
    fail(err) {
      console.log('fail', err)
    },

  },
};
</script>